<template>
  <p-container class="no-bg">
    <template slot="left" v-if="showLeft">
      <a-tree
        :show-line="true"
        :default-expanded-keys="['0-0']"
        :tree-data="treeData"
      ></a-tree>
    </template>
    <template slot="toolbar">
      <!-- <a-button type="primary" @click="handleEdit">编辑</a-button>
      <a-button type="primary">Excel导出</a-button> -->
       <a-input-search
       
        placeholder="搜索"
        enter-button="搜索"
        size="default"
        @search="onSearch"
      />
    </template>

    <a-table
      bordered
      :data-source="dataSource"
      :columns="columns"
      size="middle"
      :row-selection="rowSelection"
      :loading="loading"
    >
      <a
        href="javascript:;"
        @click="analysisShowModal(col)"
        slot="analysisAction"
        slot-scope="text, col"
        >关联分析</a
      >
    </a-table>
    <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
      <div class="echartLayout">
        <div
          id="container"
          style="width:100%; height:600px; overflow:hidden;"
        ></div>
      </div>
    </a-card>
  </p-container>
</template>
<script>
import imgSrc from "./../../../../assets/img/modules/result/test2.png";
import { getcggl,getgxt } from "../../../../api/result";
import * as echarts from 'echarts';

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
   
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    var cgArry = [];

    selectedRows.forEach((item) => {
      var cgobj = {};
      cgobj.zcxmguid = item.zcxmguid;
      cgArry.push(cgobj);
    });
    console.log(cgArry);
    localStorage.removeItem("cgArry");
    localStorage.setItem("cgArry", JSON.stringify(cgArry));
   
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
    console.log(record, selected, selectedRows);
    localStorage.removeItem("cgArry");
    localStorage.setItem("cgArry", JSON.stringify(cgArry));
    
  },
};
const columns = [
  {
    title: "项目名称",
    dataIndex: "zcxmmc",
    width: "10%",
  },
  {
    title: "项目名称(英文)",
    dataIndex: "zcxmmcUsa",
  },
  {
    title: "电力科技成果分类名称",
    dataIndex: "cmflmc",
  },
  {
    title: "电力科技成果分类代码",
    dataIndex: "cmfldm",
  },
  {
    title: "",
    dataIndex: "analysis",
    scopedSlots: { customRender: "analysisAction" },
  },
];
const dataSource = [
  {
    key: "0",
    name: "项目1",
    englishName: "english",
    classification: "名称1",
    classificationCode: "001",
    analysis: "关联分析",
  },
];
export default {
  name: "personRelation",
  data() {
    return {
      loading: false,
      treeData: [],
      rowSelection,
      dataSource,
      columns,
      updateVisible: false,
      // img: pic,
      myChart: null,
      chartData: [],
      chartLink: [],
      url:"apia/cgkZcxm/queryAllRealtion",
      url2:'apia/cgkZcxm/queryRealtion',
      data2:[]
    };
  },
  computed: {
    type() {
      return this.$route.name.substr(this.$route.name.lastIndexOf("_") + 1);
    },
    // showLeft(){
    //   return this.type!='all'
    // }
  },
  watch: {
    $route(to, from) {
      this.initPage();
    },
  },
  mounted() {
     getcggl(this.url)
      .then((res) => {
        console.log(res);
        this.dataSource = res.data;
        console.log(this.dataSource)
      })
      .catch((e) => {})
      .catch((e) => {});
    this.initEchart();
   
  },
  created() {
    this.initPage();
  },
  methods: {
    initPage() {
      //tree
      switch (this.type) {
        case "subject":
          this.treeData = subjectTreeData;
          break;
        case "device":
          this.treeData = deviceTreeData;
          break;
        default:
          break;
      }
      //clear searchbar

      //loadList
    },
    analysisShowModal(col) {
      this.analysisVisible = true;
      this.mdl = { ...col };
      
      if(col.cmfldm!=''){
getgxt(this.url2+'?fldm='+col.cmfldm)
.then(res=>{
  console.log(res.data.data)
  this.data2=res.data.data
  this.initEchart()
})
      }
    },
    handleOk(e) {
      console.log(e);
      this.updateVisible = false;
    },
    onSearch(value) {
      console.log(value);
      getcggl(this.url+'?name='+value)
      .then(res=>{
        console.log(res.data)
        this.dataSource = res.data;
      })
    },
    initEchart() {
      let dom = document.getElementById("container");
      this.myChart = echarts.init(dom);
      this.chartData = this.data2;
      this.chartLink = this.linkEChart();
      let option = {
        tooltip: {
          show: false,
        },
        series: [
          {
            edgeLabel: {
              normal: {
                formatter: "{c}",
                show: true,
              },
            },
            edgeSymbol: "circle",
            force: {
              repulsion: 2000,
            },
            layout: "force",
            roam: true,
            itemStyle: {
              normal: {
                color: "#6495ED",
              },
              //鼠标放上去有阴影效果
              emphasis: {
                shadowColor: "#3721db",
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 40,
              },
            },
            label: {
              normal: {
                show: true,
              },
            },
            //头像
            symbol: `image://${imgSrc}`,
            symbolSize: 86,
            type: "graph",
            links: this.chartLink,
            data: this.chartData,
          },
        ],
      };
      this.myChart.setOption(option);
      this.myChart.on("click", function(params) {
        console.log(params.data); //获取点击的头像的数据信息
      });
    },
    /**
     * 数据集合
     */
    dataEChart() {
      let data = [
        {
          name: "张1",
          symbolSize: 76,
          id: "1",
        },
        {
          name: "张2",
          id: "2",
        },
        {
          name: "张3",
          id: "3",
        },
        {
          name: "张4",
          id: "4",
        },
        {
          name: "张5",
          id: "5",
        },
        {
          name: "张6",
          id: "6",
        },
        {
          name: "张7",
          id: "7",
        },
        {
          name: "张6",
          id: "8",
        },
      ];
      return data;
    },
    /**
     * 关系数据集合
     */
    linkEChart() {
      let dataLink=[];
      for(var i=1;i<=this.data2.length;i++){
        dataLink.push({ value: "", source: "1", target: i++ },)
      }
      console.log(dataLink)
      
      return dataLink;
    },
  },
};
</script>

<style scoped>

.ant-card{
  height: 900px  !important;
}
.ant-input-search {
  width: 40%;
  margin: 0 8px 8px 0;
}
</style>
